<template>
  <v-container fluid>
    <v-card class="mt-2">
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :loading="buscandoConfiguracoes"
          loading-text="Carregando as configurações..."
          :items="configuracoesDinamicasERP"
          item-class="class"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }"
        >
          <template #top>
            <v-container fluid class="d-flex">
              <v-toolbar-title>
                <span> Integração Systextil</span>
              </v-toolbar-title>

              <v-spacer />

              <v-btn color="primary" :disabled="!permiteAdicionar" @click="dialogEdicao = true">
                Adicionar
              </v-btn>
            </v-container>
          </template>

          <template #[`item.documentoAdquirente`]="{ item }">
            {{ formataCpfCnpj(item.documentoAdquirente) }}
          </template>

          <template #[`item.acoes`]="{ item }">
            <div class="d-flex flex-nowrap justify-center">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-2"
                    :disabled="!permiteEditar"
                    v-on="on"
                    @click="abrirDialogEdicao(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>

                <span> Editar </span>
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-2"
                    :disabled="!permiteRemover"
                    v-on="on"
                    @click="abrirDialogExclusao(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>

                <span> Excluir </span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <v-card class="mt-5">
      <v-container fluid>
        <v-data-table
          :headers="headersLogs"
          :loading="buscandoLogs"
          loading-text="Carregando os logs..."
          :items="logs"
          item-class="class"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }"
        >
          <template #top>
            <v-container fluid class="d-flex">
              <v-toolbar-title> Logs </v-toolbar-title>

              <v-spacer />

              <v-tooltip left>
                <template #activator="{ on }">
                  <v-icon v-on="on" @click="buscarLogs()"> mdi-sync </v-icon>
                </template>

                <span> Atualizar </span>
              </v-tooltip>
            </v-container>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />

    <v-dialog v-model="dialogEdicao" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline"> {{ formTitle }} </span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="validForm" @submit.prevent>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    v-model="configuracaoERP.codigoEmpresa"
                    label="Código Empresa"
                    type="number"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <CampoDeCpfOuCnpj
                    v-model="configuracaoERP.documentoAdquirente"
                    label="CPF / CNPJ Adquirente *"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    v-model="configuracaoERP.formaPagamento"
                    label="Forma Pagamento *"
                    required
                    :rules="[obrigatorio]"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="configuracaoERP.portadorContaTransitoriaTituloOriginal"
                    label="Portador Conta Transitória Título Original *"
                    required
                    :rules="[obrigatorio]"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="configuracaoERP.contaCorrenteContaTransitoriaTituloOriginal"
                    label="Conta Corrente Transtitória Título Original *"
                    required
                    :rules="[obrigatorio]"
                    type="number"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="configuracaoERP.portadorContaTransitoriaTituloSubstituto"
                    label="Portador Conta Transitória Título Substituto *"
                    required
                    :rules="[obrigatorio]"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="configuracaoERP.contaCorrenteContaTransitoriaTituloSubstituto"
                    label="Conta Corrente Transtitória Título Substituto *"
                    required
                    :rules="[obrigatorio]"
                    type="number"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    v-model="configuracaoERP.codigoHistoricoBaixaTituloOriginal"
                    label="Código Histórico Baixa Título Original *"
                    required
                    :rules="[obrigatorio]"
                    type="number"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    v-model="configuracaoERP.contaContabilTaxa"
                    label="Conta Contábil Taxa *"
                    required
                    :rules="[obrigatorio]"
                    type="number"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    v-model="configuracaoERP.tipoNovoTitulo"
                    label="Tipo Novo Título *"
                    required
                    :rules="[obrigatorio]"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-alert v-if="showMessageError" dense outlined type="error">
                    {{ messageError }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="red darken-1" text @click="fecharDialogEdicao"> Cancelar </v-btn>

          <v-btn
            color="green darken-1"
            text
            :disabled="!validForm"
            :loading="salvando"
            @click="salvarConfiguracao"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogExclusao" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"> Excluir </v-card-title>

        <v-card-text> Tem certeza que deseja remover esta configuração? </v-card-text>

        <v-card-text>
          <v-alert v-if="showMessageError" dense outlined type="error">
            {{ messageError }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="red darken-1" text @click="fecharDialogExclusao()"> Cancelar </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="excluindoConfiguracao"
            @click="excluirConfiguracao"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { formataCpfCnpj, formatDateTimeWithSeconds } from '@/shareds/formatacoes'
import { obrigatorio } from '@/shareds/regrasForm'
import IntegracaoService from '@/services/integracao/integracao'
import TituloService from '@/services/titulo/titulo'
import CampoDeCpfOuCnpj from '@/components/ui/CampoDeCpfOuCnpj'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import Snackbar from '@/models/ui/snackbar'
import store from '@/store'

export default {
  components: {
    SnackbarMessage,
    CampoDeCpfOuCnpj
  },

  data: () => ({
    configuracoesDinamicasERP: [],
    configuracaoERP: {},
    headers: [
      { text: 'Código Empresa', value: 'codigoEmpresa', align: 'center' },
      { text: 'Documento Adquirente', value: 'documentoAdquirente', align: 'center', width: '160' },
      { text: 'Forma Pagamento', value: 'formaPagamento', align: 'center' },
      {
        text: 'Código Histórico Baixa Título Original',
        value: 'codigoHistoricoBaixaTituloOriginal',
        align: 'center',
        width: '155',
        sortable: false
      },
      {
        text: 'Conta Corrente Transitória Título Original',
        value: 'contaCorrenteContaTransitoriaTituloOriginal',
        align: 'center',
        width: '170',
        sortable: false
      },
      {
        text: 'Portador Conta Transitória Título Original',
        value: 'portadorContaTransitoriaTituloOriginal',
        align: 'center',
        width: '170',
        sortable: false
      },
      {
        text: 'Conta Corrente Transitória Título Substituto',
        value: 'contaCorrenteContaTransitoriaTituloSubstituto',
        align: 'center',
        width: '175',
        sortable: false
      },
      {
        text: 'Portador Conta Transitória Título Substituto',
        value: 'portadorContaTransitoriaTituloSubstituto',
        align: 'center',
        width: '180',
        sortable: false
      },
      {
        text: 'Conta Contábil Taxa',
        value: 'contaContabilTaxa',
        align: 'center',
        width: '120',
        sortable: false
      },
      {
        text: 'Tipo Novo Título',
        value: 'tipoNovoTitulo',
        align: 'center',
        width: '125',
        sortable: false
      },
      { text: '', value: 'acoes', width: '1%' }
    ],
    headersLogs: [
      { text: 'Data Execução', value: 'dataExecucao', width: '15%', divider: true },
      {
        text: 'Código Título',
        value: 'codigoTitulo',
        width: '10%',
        divider: true,
        sortable: false
      },
      { text: 'Erro', value: 'erro', sortable: false }
    ],
    logs: [],
    buscandoLogs: false,
    salvando: false,
    buscandoConfiguracoes: false,
    excluindoConfiguracao: false,
    dialogEdicao: false,
    dialogExclusao: false,
    snackbar: new Snackbar(),
    validForm: true,
    messageError: '',
    showMessageError: false,
    formataCpfCnpj: formataCpfCnpj,
    formatDateTimeWithSeconds: formatDateTimeWithSeconds,
    obrigatorio: obrigatorio
  }),

  computed: {
    formTitle() {
      return `${
        this.configuracoesDinamicasERP.indexOf(this.configuracaoERP) === -1 ? 'Adicionar' : 'Editar'
      } configuração`
    },
    permiteAdicionar() {
      return store.getters.verificaPermissaoAcesso('IntegracaoSystextil', 'ADICIONAR')
    },
    permiteEditar() {
      return store.getters.verificaPermissaoAcesso('IntegracaoSystextil', 'EDITAR')
    },
    permiteRemover() {
      return store.getters.verificaPermissaoAcesso('IntegracaoSystextil', 'REMOVER')
    }
  },

  created() {
    this.buscarConfiguracoes()
    this.buscarLogs()
  },

  methods: {
    buscarConfiguracoes() {
      this.buscandoConfiguracoes = true

      IntegracaoService.buscarconfiguracoesDinamicaERP('SYSTEXTIL')
        .then((response) => (this.configuracoesDinamicasERP = response))
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os permissoes.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoConfiguracoes = false))
    },

    buscarLogs() {
      this.buscandoLogs = true

      TituloService.buscarLogsTicketTitulos()
        .then(
          (response) =>
            (this.logs = response.map((log) => ({
              ...log,
              dataExecucao: formatDateTimeWithSeconds(log.dataHoraCriacao)
            })))
        )
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os logs a configuração.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoLogs = false))
    },

    abrirDialogEdicao(configuracaoDinamicaSelecionada) {
      this.configuracaoERP = configuracaoDinamicaSelecionada
      this.dialogEdicao = true
    },

    fecharDialogEdicao() {
      this.dialogEdicao = false
      this.configuracaoERP = {}
      this.$refs.form.resetValidation()
    },

    abrirDialogExclusao(configuracaoDinamicaSelecionada) {
      this.configuracaoERP = configuracaoDinamicaSelecionada
      this.dialogExclusao = true
    },

    fecharDialogExclusao() {
      this.dialogExclusao = false
      this.configuracaoERP = {}
    },

    async salvarConfiguracao() {
      this.salvando = true

      const index = this.configuracoesDinamicasERP.indexOf(this.configuracaoERP)

      if (index > -1) {
        try {
          const response = await IntegracaoService.atualizarConfiguracaoDinamicaErp(
            this.configuracaoERP.id,
            this.configuracaoERP
          )

          Object.assign(this.configuracoesDinamicasERP[index], response)

          this.snackbar.snackTextParam = 'Configuração atualizada com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true

          this.fecharDialogEdicao()
        } catch (error) {
          this.messageError = `${error.response.status} | ${
            error.response.data.message === undefined ? error.message : error.response.data.message
          }`
          this.showMessageError = true
        } finally {
          this.salvando = false
        }

        return
      }

      try {
        const response = await IntegracaoService.adicionarConfiguracaoDinamicaErp('SYSTEXTIL', {
          ...this.configuracaoERP,
          documentoAdquirente: this.configuracaoERP.documentoAdquirente
            .replaceAll('.', '')
            .replaceAll('/', '')
            .replaceAll('-', '')
        })

        this.configuracoesDinamicasERP.push(response)

        this.snackbar.snackTextParam = 'Configuração adicionada com sucesso.'
        this.snackbar.snackTypeParam = 'sucess'
        this.snackbar.showSnackParam = true

        this.fecharDialogEdicao()
      } catch (error) {
        this.messageError = `${error.response.status} | ${
          error.response.data.message === undefined ? error.message : error.response.data.message
        }`
        this.showMessageError = true
      } finally {
        this.salvando = false
      }
    },

    async excluirConfiguracao() {
      this.excluindoConfiguracao = true

      try {
        await IntegracaoService.excluirConfiguracaoDinamicaErp(this.configuracaoERP.id)

        this.snackbar.snackTextParam = 'Configuração excluída com sucesso.'
        this.snackbar.snackTypeParam = 'sucess'
        this.snackbar.showSnackParam = true

        this.configuracoesDinamicasERP.splice(
          this.configuracoesDinamicasERP.indexOf(this.configuracaoERP),
          1
        )

        this.fecharDialogExclusao()
      } catch (error) {
        this.messageError = `${error.response.status} | ${
          error.response.data.message === undefined ? error.message : error.response.data.message
        }`
        this.showMessageError = true
      } finally {
        this.excluindoConfiguracao = false
      }
    }
  }
}
</script>
<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: calc(0.4rem + 1vmin);
}

::v-deep .v-data-table-header {
  vertical-align: top;
}
</style>
