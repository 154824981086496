import request from '@/common/request'

class IntegracaoService {
  buscarConfiguracoesAdquirente(adquirente) {
    return request.get(`/integracoes/configuracoes/adquirentes/${adquirente}`)
  }

  adicionarConfiguracaoAdquirente(adquirente, empresaId) {
    return request.post(
      `/integracoes/configuracoes/adquirentes/${adquirente}/empresas/${empresaId}`
    )
  }

  atualizar(configuracaoId, data) {
    return request.put(`/integracoes/configuracoes/${configuracaoId}`, data)
  }

  buscarLogs(configuracaoId) {
    return request.get(`/integracoes/configuracoes/${configuracaoId}/logs`)
  }

  buscarStackTrace(configuracaoId, logId) {
    return request.get(`/integracoes/configuracoes/${configuracaoId}/logs/${logId}`)
  }

  excluirConfiguracao(configuracaoId) {
    return request.delete(`/integracoes/configuracoes/${configuracaoId}`)
  }

  /* ERP */

  buscarconfiguracoesDinamicaERP(erp) {
    return request.get(`/integracoes/configuracoes/erps/${erp}`)
  }

  adicionarConfiguracaoDinamicaErp(erp, data) {
    return request.post(`/integracoes/configuracoes/erps/${erp}`, data)
  }

  atualizarConfiguracaoDinamicaErp(configuracaoERPId, data) {
    return request.put(`/integracoes/configuracoes/erps/${configuracaoERPId}`, data)
  }

  excluirConfiguracaoDinamicaErp(configuracaoERPId) {
    return request.delete(`/integracoes/configuracoes/erps/${configuracaoERPId}`)
  }
}

export default new IntegracaoService()
