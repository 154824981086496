import request from '@/common/request'

class TituloService {
  buscarTudo(params) {
    return request.get('/titulos', {
      params
    })
  }

  buscarTitulosCompletos(params) {
    return request.get('/titulos/completos', {
      params
    })
  }

  excluirTitulo(tituloId) {
    return request.delete(`/titulos/${tituloId}`)
  }

  editarTitulo(tituloId, data) {
    return request.patch(`titulos/${tituloId}`, data)
  }

  buscarLogsTicketTitulos() {
    return request.get(`/tickets/titulos/logs`)
  }

  buscarValorTotal(queryParams) {
    return request.get('/titulos/valorTotal', {
      params: {
        ...queryParams
      }
    })
  }

  exportarTitulos(params) {
    return request.get('/titulos/tituloExport?organizacaoId=c7b4f653-beca-4f94-bde2-177ece6f9c2f', {
      params
    })
  }

  exportarTitulosSemConciliacao(params) {
    return request.get(
      '/titulos/tituloSemConciliacaoExport?organizacaoId=c7b4f653-beca-4f94-bde2-177ece6f9c2f',
      {
        params
      }
    )
  }
}

export default new TituloService()
